import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import mixin from "./mixins/index"
import { i18n } from "./lang/config.js"
import * as VueGoogleMaps from "vue2-google-maps"
// hight-charts
import Highcharts from "highcharts"
import ExportingHighcharts from "highcharts/modules/exporting"
import VueHighcharts from "vue-highcharts"
import accessibility from "highcharts/modules/accessibility"
// plugins
import cookie from "./plugins/cookie"
import moment from "./plugins/moment"
import socket from "./plugins/socketIO/socket"
import { vsPopup, vsIcon } from "vuesax"
import "vuesax/dist/vuesax.css"
import "material-icons/iconfont/material-icons.css"

Vue.use(vsIcon)
Vue.use(vsPopup)

Vue.config.productionTip = false

accessibility(Highcharts)
ExportingHighcharts(Highcharts)

// mixins
const mixins = { mixin }
Object.keys(mixins).forEach((key) => {
	Vue.mixin(mixins[key])
})

// plugin registration
const plugins = { cookie, moment, socket }
Object.keys(plugins).forEach((key) => {
	Vue.use(plugins[key])
})

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_API_KEY,
		libraries: "visualization",
	},
})
Vue.use(VueHighcharts, { Highcharts })

// event-bus
const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus
export default eventBus

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app")
