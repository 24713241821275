import VueCookie from "vue-cookie"
import { api } from "../plugins/axios"

export default {
  async updateSystemConfigStateValue ({ state }) {
    await Promise.all([
      api.get("/system-config/get-general-system-config"),
      api.get("/system-config/get-reviewer-config"),
    ])
      .then(([general_system_config_response, reviewer_config_response] = []) => {
        const general_system_config = general_system_config_response.data?.data
        const reviewer_config = reviewer_config_response.data?.data

        if (general_system_config && reviewer_config) {
          const systemconfig = { general_system_config, reviewer_config }
          VueCookie.set("ckpsystemconfig", JSON.stringify(systemconfig), { SameSite: "Lax", Secure: true })
          state.systemconfig = systemconfig
        }
      })

  },
}