export default {
	userdata: null,
	userRoles: [
		{
			label: "SuperAdmin",
			value: -1,
		},
		{
			label: "Admin",
			value: 1,
		},
		{
			label: "Supervisor",
			value: 2,
		},
		{
			label: "Employee",
			value: 3,
		},
		{
			label: "Reviewer",
			value: 4,
		},
	],
	requestCounter: 0,
	systemconfig: null,
}
