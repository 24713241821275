import cookie from "vue-cookie"

export default {
	userdata (state) {
		return state.userdata
	},
	systemconfig (state) {
		let systemconfig = state.systemconfig

		if (!systemconfig) {
			systemconfig = cookie.get("ckpsystemconfig")
			systemconfig = (systemconfig && systemconfig !== "undefined") ? JSON.parse(systemconfig) : null
		}

		return systemconfig
	},
}