import Vue from "vue"
import VueRouter from "vue-router"
import cookie from "vue-cookie"

Vue.use(VueRouter)

// the below code is used to avoid reduntant same location push errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/login",
			name: "login",
			component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
			meta: { rolePermit: [-1, 1, 2, 3, 4] },
		},
		{
			path: "/dashboard",
			name: "dashboard",
			component: () => import(/* webpackChunkName: "dashboard" */ "../views/dashboard/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/incidents",
			name: "incidents",
			component: () => import(/* webpackChunkName: "incidents" */ "../views/incidents/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/vpi",
			name: "vpi",
			component: () => import(/* webpackChunkName: "vpi" */ "../views/vpi/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/sightings",
			name: "sightings",
			component: () => import(/* webpackChunkName: "sightings" */ "../views/sightings/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/coverage",
			name: "coverage",
			component: () => import(/* webpackChunkName: "coverage" */ "../views/coverage/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/tracker",
			name: "tracker",
			component: () => import(/* webpackChunkName: "tracker" */ "../views/tracker/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/live",
			name: "live",
			component: () => import(/* webpackChunkName: "live" */ "../views/live/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/driver-performance",
			name: "driver-performance",
			component: () => import(/* webpackChunkName: "driver-performance" */ "../views/driverPerfomance/Index.vue"),
			meta: { rolePermit: [-1, 1, 2] },
		},
		{
			path: "/reports",
			name: "reports",
			component: () => import(/* webpackChunkName: "Reports" */ "../views/Reports/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/settings",
			name: "settings",
			component: () => import(/* webpackChunkName: "settings" */ "../views/settings/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/external-incidents",
			name: "external-incidents",
			component: () => import(/* webpackChunkName: "external-incidents" */"../views/externalIncidents/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3] },
		},
		{
			path: "/reviewer",
			name: "reviewer",
			component: () => import(/* webpackChunkName: "reviewer" */"../views/reviewer/Index.vue"),
			meta: { rolePermit: [4] },
			beforeEnter: (to, from, next) => {
				let userdata = cookie.get("ckpuser")
				userdata = userdata ? JSON.parse(userdata) : null

				if (userdata.userRole === 4) next()
				else next("/dashboard")
			},
		},
		{
			path: "/incidents-review",
			name: "incidents-review",
			component: () => import(/* webpackChunkName: "incidents-review" */"../views/incidents-review/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3, 4] },
			beforeEnter: (to, from, next) => {
				let userdata = cookie.get("ckpuser")
				userdata = userdata ? JSON.parse(userdata) : null

				if (userdata.userRole === 4) next()
				else next("/dashboard")
			},
		},
		{
			path: "/profile",
			name: "profile",
			component: () => import(/* webpackChunkName: "profile" */"../views/Profile.vue"),
			meta: { rolePermit: [-1, 1, 2, 3, 4] },
    },
		{
			path: "/frames/:module/:id",
			name: "frames",
			component: () => import(/* webpackChunkName: "frames" */"../views/frames/Index.vue"),
			meta: { rolePermit: [-1, 1, 2, 3, 4] },
    },
    {
			path: "*",
			meta: { rolePermit: [-1, 1, 2, 3, 4] },
			beforeEnter: (to, from, next) => {
				let userdata = cookie.get("ckpuser")
				userdata = userdata ? JSON.parse(userdata) : null

				if (cookie.get("ckptoken") && (userdata && userdata._id)) {
					if (userdata.userRole === 4) return next("/reviewer")
					return next("/dashboard")
				} else {
					next("/login")
				}
			},
		},
	],
})

router.beforeEach((to, from, next) => {
	let userdata = cookie.get("ckpuser")
	userdata = userdata ? JSON.parse(userdata) : null

	if (cookie.get("ckptoken") && (userdata && userdata._id)) {
		if (!(to.meta?.rolePermit.includes(userdata.userRole))) {
			if (userdata.userRole === 4) return next("/reviewer")
			return next("/dashboard")
		}
		
		if (to.name === "login") return next("/dashboard")
		return next()
	}

	if (to.name === "login") return next()
	next(`/login?callback=${to.path}`)
})

export default router
