import axios from "axios"
import cookie from "vue-cookie"
import store from "../store/index"
import eventBus from "../main"


const token = cookie.get("ckptoken")

// Creating an axios instance with custom parameters
const api = axios.create({
	baseURL: process.env.VUE_APP_API_BASEURL,
	withCredentials: true,
	headers: {
		common: {
			"x-env": process.env.NODE_ENV,
			"ckptoken": token,
      "x-web-version": process.env.VUE_APP_VERSION,
      "x-web-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
		},
	},
})

// Custom interceptor to cancel duplicate requests
const requestMap = new Map()
api.interceptors.request.use((config) => {
	const { url, params } = config
	const key = JSON.stringify({ url, params })

  const locale = localStorage.getItem(`${process.env.VUE_APP_APP}_langpref`)
  config.headers.set("Accept-Language", locale)

  store.commit("updateRequestCounter", 1)
	if (store.state.requestCounter > 0) {
		eventBus.$emit("loading", true)
	}

	// If a request with the same URL, params, is already in progress, cancelling it
	if (requestMap.has(key)) requestMap
    .get(key)
    .cancel("Duplicate request canceled")

	// Create a new cancel token for this request
	const cancelToken = axios.CancelToken.source()
	config.cancelToken = cancelToken.token

	// Add the request to the map
	requestMap.set(key, cancelToken)

	return config
}, (error) => {
  store.commit("updateRequestCounter", -1)
  if (store.state.requestCounter === 0) {
    eventBus.$emit("loading", false)
  }

  return Promise.reject(error)
})


api.interceptors.response.use((res) => {
  store.commit("updateRequestCounter", -1)
  if (store.state.requestCounter === 0) {
    eventBus.$emit("loading", false)
  }

  return res
}, (error) => {
  store.commit("updateRequestCounter", -1)
  if (store.state.requestCounter === 0) {
    eventBus.$emit("loading", false)
  }

  if (!error || !error.response) return Promise.reject(error)

  const { status, data, statusText } = error.response
  let message = data?.err?.message || (data || statusText)
  message = message.message || message

  if (status >= 400) {
    switch (status) {
      case 401:
        store.commit("logout")
        eventBus.$emit("snackbar", {
          message,
          color: "error darken-1",
        })
        break
      case 429:
        eventBus.$emit("snackbar", {
          message: "Too many requests, please try again later",
          color: "error",
        })
        break
      default:
        eventBus.$emit("snackbar", {
          message,
          color: "error darken-1",
        })
        break
    }
  }

  return Promise.reject(error)
})


export { api, token }
