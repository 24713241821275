import cookie from "vue-cookie"
import router from "../router"
import socket from "../plugins/socketIO/config"
import { api } from "../plugins/axios"

export default {
	updateUserData (state, userdata) {
		userdata = userdata || JSON.parse(cookie.get("ckpuser"))
		const userRoleObj = (state.userRoles || []).find((userRoleItem) => userdata?.userRole && userRoleItem.value === userdata?.userRole)
		state.userdata = { ...userdata, userRole: userRoleObj }
	},
	logout () {
		api.defaults.headers.common["ckptoken"] = null
		socket.emit("logout")
		socket.disconnect()
		cookie.delete("ckptoken")
		cookie.delete("ckpuser")
		cookie.delete("ckpsystemconfig")
		router.push("/login")
	},
	updateRequestCounter (state, value) {
		state.requestCounter += value
	},
	updateSystemConfig (state, value) {
		state.systemconfig = value
	},
}
