import Vue from "vue"
import VueI18n from "vue-i18n"
import { en } from "./translations/en.js"
import { ar } from "./translations/ar.js"
// import cookie from 'vue-cookie'

Vue.use(VueI18n)

// const locale = (cookie.get(`${ process.env.VUE_APP_APP }_locale`) || 'ar')
const locale = localStorage.getItem(`${process.env.VUE_APP_APP}_langpref`)
// const locale = 'ar'
export const i18n = new VueI18n({
	locale,
	fallbackLocale: locale,
	messages: { en, ar },
})
