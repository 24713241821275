import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
// import { i18n } from '../lang/config'

Vue.use(Vuetify)

const locale = localStorage.getItem(`${process.env.VUE_APP_APP}_langpref`)
export default new Vuetify({
	rtl: (locale === "ar"),
})
