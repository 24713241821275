import { mapGetters } from "vuex"
import { api } from "../plugins/axios"
import moment from "moment"
export default {
	data: () => ({
		// DOM
		$_windowHeight: window.innerHeight,
	}),
	mounted() {
		window.onresize = () => {
			this.$_windowHeight = window.innerHeight
		}
	},
	computed: {
		...mapGetters(["userdata", "systemconfig"]),
		productionenv() {
			return !!["docker", "production"].includes(process.env.NODE_ENV)
		},
		rtlEnabled() {
			return this.$vuetify.rtl
		},
		framesbucketurl() {
			return process.env.VUE_APP_FRAMES_BUCKET_URL
		},
		videosbucketurl() {
			return process.env.VUE_APP_VIDEOS_BUCKET_URL
		},
	},
	methods: {
		// api methods
		$_execute(method, url, payload, config) {
			return api[method](url, payload, config)
		},
		// validations
		$_setValidation(type, isrequired = true) {
			let result = []
			switch (type) {
				case "requiredValidation":
					result = [(v) => !!v || this.$t("Required"), (v) => !/^\s*$/.test(v) || this.$t("Required")]
					break
				case "emailValidation":
					result = [
						(v) => !!v || this.$t("Required"),
						(v) =>
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
								v,
							) || this.$t("InvalidEmail"),
					]
					break
				case "numberValidation":
					result = [(v) => !!v || this.$t("Required"), (v) => /^([\d+]{1, 12})$/.test(v) || this.$t("InvalidNumber")]
					break
				case "wholeNumberValidation":
					result = [(v) => !!v || this.$t("Required"), (v) => /^([0-9]*)$/.test(v) || this.$t("InvalidNumber")]
					break
				case "stringValidation":
					result = [(v) => !!v || this.$t("Required"), (v) => /^([\D+]{1, 20})$/.test(v) || this.$t("InvalidName")]
					break
				case "noSpaceValidation":
					result = [...(isrequired ? [(v) => !!v || this.$t("Required")] : []), (v) => /^\S+$/.test(v) || this.$t("SpacesAreNotAllowed")]
					break
				default:
					result = []
					break
			}
			return result
		},
		// general
		$_cloneVariable (payload) {
			return payload ? JSON.parse(JSON.stringify(payload)) : null
		},
		$_copytoclipboard(txt) {
			let textArea = document.createElement("textarea")
			textArea.value = txt
			let item = document
			setTimeout(() => {
				item.getElementById("app").appendChild(textArea)
				textArea.select()
				item.execCommand("Copy")
				textArea.remove()
				this.$root.$emit("snackbar", { color: "success", message: this.$t("TextCopied") })
			}, 30)
		},
		// dates
		$_generateDatesInRange ({ start, end, inputformat, outputformat = inputformat } = {}) {
			let dates = []
			let currentDate = moment(start, inputformat)
			let lastDate = moment(end, inputformat)

			while (currentDate <= lastDate) {
				dates.push(currentDate.format(outputformat))
				currentDate.add(1, "days")
			}

			return dates
		},
		$_convertMinutesToHours(minutes) {
			const hours = Math.floor(minutes / 60)
			const remainingMinutes = minutes % 60

			const formattedHours = hours.toString().padStart(2, "0")
			const formattedMinutes = remainingMinutes.toString().padStart(2, "0")

			return formattedHours + ":" + formattedMinutes
		},
	},
}
