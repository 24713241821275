import { io } from "socket.io-client"
import cookie from "vue-cookie"

const socket = io((process.env.VUE_APP_SOCKET_BASEURL), {
	transports: ["websocket"],
	auth: {
		token: cookie.get("ckptoken"),
		"x-web-version": process.env.VUE_APP_VERSION,
	},
	extraHeaders: {
		"x-web-version": process.env.VUE_APP_VERSION,
	},
	reconnection: true,
	reconnectionAttempts: Infinity, // Infinite attempts to reconnect
	reconnectionDelay: 1000, // Initial delay in milliseconds before reconnecting
	reconnectionDelayMax: 5000, // Maximum delay in milliseconds
	timeout: 20000, // Connection timeout in milliseconds
  
})

export default socket
